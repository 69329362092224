import { TransportStatus } from 'requests/objects/transport'
import parseJson from 'helpers/methods/parseJson'
import Time from 'helpers/methods/time'
import { isValidDate } from 'helpers/methods/common'
import { Translations } from "types/translations" // eslint-disable-line

const REG_END_BY_DATE = /(Date|DateStart|DateEnd)$/

/**
 * Translate an element by an object
 * @param {'fr' | 'en' | 'nl' | string} lang Language
 * @param {import('types/translations').TranslationsObjectType | string} obj Object to translate, default to undifined to use "Translations" object
 * @param {{[key: string]: string}=} args Args to replace
 * @returns {string} Result
 */
export function wfTObj(lang, obj, args = {}) {
    let translation = obj?.[lang] ?? ''
    Object.keys(args).forEach(key => {
        let value = args[key]

        switch (key) {
            case 'PlannedDeliveryDate': {
                const argDate = new Date(value)
                if (!isValidDate(argDate))
                    break
                if (argDate.getHours() < 13)
                    value = Translations.deliveryMorning?.[lang]
                else
                    value = Translations.deliveryAfternoon?.[lang]
                value = value.replace('{{date}}', Time(argDate).getCleanDate({
                    month: 'numeric', day: 'numeric', year: 'numeric',
                }))
                break
            }
            case 'TransportReserves':
                value = parseJson(value)?.[lang] ?? value
                break
            default: {
                if (REG_END_BY_DATE.test(key)) {
                    const argDate = new Date(value)
                    if (isValidDate(argDate))
                        value = Time(argDate).getCleanDate({
                            month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',
                        })
                }
                break
            }
        }

        translation = translation.replace(`{{${key}}}`, value)
    })

    translation = translation.replace('{{Platform}} -', '')
    translation = translation.replace(' 00:00', '')
    translation = translation.replace('{{Platform}}', '')
    translation = translation.replace('{{TransportReserves}}', '/')
    translation = translation.replace(' le {{RealDeliveryDate}}', '')
    translation = translation.replace(' on {{RealDeliveryDate}}', '')
    translation = translation.replace(' op {{RealDeliveryDate}}', '')

    return translation
}

/**
 * get step types from transport statuses
 * @param {TransportStatus[]} transportStatuses transportStatuses
 * @returns {import("components/pages/statusData").StepType[]} stepTypes
 */
export default function getStepTypes(transportStatuses) {
    const lang = this?.props?.lang || 'en'

    return transportStatuses.map(ts => ({
        date: ts.date,
        label: wfTObj(lang, parseJson(ts.tmsTransportStatus?.label)),
        subLabel: wfTObj(lang, parseJson(ts.tmsTransportStatus?.subLabel), parseJson(ts.args || '')),
        type: ts.tmsTransportStatus?.type,
    }))
}
