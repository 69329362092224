import React, { PureComponent } from 'react'
import {
    Breadcrumb, MessageBar, CommandBar,
} from '@fluentui/react'
import Header from 'components/_layout/header'
import history from 'helpers/history'
import Menu from 'components/_layout/menu'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from 'styles/pages/_layout.module.scss'
import EProfile from 'types/users/enums/profile'
import { tKey, tObj } from 'helpers/methods/translate'
import ECompany from 'types/companies/enums/company'

/** @debug {import('@fluentui/react').IIconProps|import('app').AppProps} */

/**
 * @typedef {object} MenuElement
 * @property {string} name Element name
 * @property {boolean} isDisplay Is element display
 * @property {LinkElement[]} links Refresh app
 */
/**
 * @typedef {object} LinkElement
 * @property {string} key Element key and URL
 * @property {string} title Element title
 * @property {import('@fluentui/react').IIconProps['iconName']} icon Element icon
 * @property {boolean} isDisplay Is element display
 * @property {LinkElement[]=} links Refresh app
 */

/**
 * @typedef {object} LayoutProps
 * @property {boolean} isDisplay Is layout is displayed
 * @property {Function} refresh Refresh app
 * @augments {PureComponent<import('app').AppProps & LayoutProps>}}
 */
export default class Layout extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {MenuElement[]} */
            menu: [],
            /** @type {boolean} Is Left Menu Open ? */
            isLeftMenuOpen: false,
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setUrl } = this.props

        setUrl(history.location.pathname)
    }

    /**
     * @inheritdoc
     * @param {object} prevProps prevProps
     */
    componentDidUpdate(prevProps) {
        const {
            isDisplay, me, lang,
        } = this.props

        const isAdmin = me?.profileId === EProfile.SuperAdmin || me?.profileId === EProfile.Admin

        if (prevProps.isDisplay !== isDisplay && !isDisplay)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                menu: [],
                isLeftMenuOpen: true,
            })

        // Re render menu if user when info user change
        if (prevProps.me !== me || prevProps.lang !== lang || isDisplay !== prevProps.isDisplay)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                menu: /** @type {MenuElement[]} */([
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/',
                                title: this.tKey('home'),
                                icon: 'Home',
                                isDisplay: true,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: me.companyId !== ECompany.JetFreeze,
                        links: [
                            {
                                key: '/transports',
                                title: this.tKey('transports'),
                                icon: 'DeliveryTruck',
                                isDisplay: [EProfile.CustomOrderEntry, EProfile.CustomOrderTracking, EProfile.CustomerAdmin].includes(me?.profileId) || isAdmin,
                                links: [
                                    {
                                        key: '/transports/all',
                                        title: this.tKey('allMyTransports'),
                                        icon: 'Link',
                                    },
                                    // {
                                    //     key: '/transports/waiting',
                                    //     title: this.tKey('waitingForValidation'),
                                    //     icon: 'Pause',
                                    // },
                                    {
                                        key: '/transports/progress',
                                        title: this.tKey('inProgress'),
                                        icon: 'Sync',
                                    },
                                    {
                                        key: '/transports/completed',
                                        title: this.tKey('completed'),
                                        icon: 'Stop',
                                    },
                                    {
                                        key: '/transports/refused',
                                        title: this.tKey('refused'),
                                        icon: 'Cancel',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/orders',
                                title: this.tKey('orderEntryFull'),
                                icon: 'Ticket',
                                isDisplay: [
                                    EProfile.CustomOrderEntry,
                                    EProfile.CustomOrderTracking,
                                    EProfile.CustomerAdmin,
                                    EProfile.Admin,
                                    EProfile.SuperAdmin,
                                    EProfile.Operator,
                                    EProfile.Commercial,
                                    EProfile.AdValorem,
                                ].includes(me?.profileId),
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/invoices',
                                title: this.tKey('invoices'),
                                icon: 'PaymentCard',
                                isDisplay: me?.companyId === ECompany.Raud && [
                                    EProfile.CustomOrderEntry,
                                    EProfile.CustomOrderTracking,
                                    EProfile.CustomerAdmin,
                                    EProfile.Admin,
                                    EProfile.SuperAdmin,
                                    EProfile.Operator,
                                    EProfile.Commercial,
                                    EProfile.AdValorem,
                                ].includes(me?.profileId),
                            },
                        ],
                    },
                    // {
                    //     name: '',
                    //     isDisplay: true,
                    //     links: [
                    //         {
                    //             key: '/waybills',
                    //             title: this.tKey('waybills'),
                    //             icon: 'InsertSignatureLine',
                    //             isDisplay: me?.companyId === ECompany.Raud && [
                    //                 EProfile.CustomOrderEntry,
                    //                 EProfile.CustomOrderTracking,
                    //                 EProfile.CustomerAdmin,
                    //                 EProfile.Admin,
                    //                 EProfile.SuperAdmin,
                    //             ].includes(me?.profileId),
                    //         },
                    //     ],
                    // },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/documents',
                                title: this.tKey('documents'),
                                icon: 'TextDocument',
                                isDisplay: [
                                    EProfile.CustomOrderEntry,
                                    EProfile.CustomOrderTracking,
                                    EProfile.CustomerAdmin,
                                    EProfile.Admin,
                                    EProfile.SuperAdmin,
                                    EProfile.Operator,
                                    EProfile.Commercial,
                                    EProfile.AdValorem,
                                ].includes(me?.profileId),
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/contacts',
                                title: this.tKey('contacts'),
                                icon: 'ContactCard',
                                isDisplay: [EProfile.CustomOrderEntry, EProfile.CustomOrderTracking, EProfile.CustomerAdmin].includes(me?.profileId),
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/tools',
                                title: this.tKey('tools'),
                                icon: 'DeveloperTools',
                                isDisplay: [EProfile.CustomOrderEntry, EProfile.CustomOrderTracking, EProfile.CustomerAdmin].includes(me?.profileId),
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/users',
                                title: this.tKey('users'),
                                icon: 'Group',
                                isDisplay: isAdmin,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/client-accounts',
                                title: this.tKey('clientAccounts'),
                                icon: 'SecurityGroup',
                                isDisplay: isAdmin,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/clients',
                                title: this.tKey('clients'),
                                icon: 'CityNext2',
                                isDisplay: isAdmin,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/home-actualities',
                                title: this.tKey('homeActualities'),
                                icon: 'Articles',
                                isDisplay: isAdmin,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/home-articles',
                                title: this.tKey('homeArticles'),
                                icon: 'News',
                                isDisplay: isAdmin,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: `/clients/${me?.clientId}`,
                                title: this.tKey('company'),
                                icon: 'CompanyDirectory',
                                isDisplay: me?.profileId === EProfile.CustomerAdmin,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/stocks',
                                title: this.tKey('stocks'),
                                icon: 'Inbox',
                                isDisplay: [EProfile.SuperAdmin, EProfile.Admin].includes(me?.profileId) || me?.client?.useStocks,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/addresses',
                                title: this.tKey('addresses'),
                                icon: 'MapPin',
                                isDisplay: me.companyId === ECompany.JetFreeze && (isAdmin || me.profileId === EProfile.Operator),
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/companies',
                                title: this.tKey('companies'),
                                icon: 'CompanyDirectory',
                                isDisplay: me?.profileId === EProfile.SuperAdmin,
                            },
                        ],
                    },
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: `/companies/${me?.companyId}`,
                                title: this.tKey('company'),
                                icon: 'CompanyDirectory',
                                isDisplay: me?.profileId === EProfile.Admin,
                            },
                        ],
                    },
                ]),
            })
    }

    /**
     * Filter menu with element that must be displayed
     * @param {MenuElement[]} menu menu
     * @returns {MenuElement[]} Filtered menu
     */
    // eslint-disable-next-line class-methods-use-this
    filterMenu(menu) {
        return menu
            .filter(group => group.isDisplay)
            .map(group => ({
                ...group,
                links: group.links.filter(link => link.isDisplay),
            }))
    }

    render() {
        const {
            selectedKeyURL, breadcrumb, command, messageBar, me, param, signOut, signIn,
            // @ts-ignore
            isDisplay, children, refresh, setMessageBar, lang, setLang, setMe, usersHandler, filesHandler, setParams, paramsHandler,
        } = this.props
        const { menu, isLeftMenuOpen } = this.state

        if (!isDisplay)
            return children

        const filteredMenu = this.filterMenu(menu)

        return (
            <section
                className={classNames(styles.layout, { 'is-menu-close': !isLeftMenuOpen })}
            >
                <Header
                    menu={filteredMenu}
                    me={me}
                    param={param}
                    refresh={() => this.setState({ menu: [] }, () => refresh())}
                    signOut={signOut}
                    lang={lang}
                    setLang={setLang}
                    setMe={setMe}
                    usersHandler={usersHandler}
                    filesHandler={filesHandler}
                    company={me?.company}
                    paramsHandler={paramsHandler}
                    setParams={setParams}
                    signIn={signIn}
                />
                <div className={styles['content-layout']}>
                    <div className={classNames(styles['menu-layout'], 'is-hidden-touch', { [styles['is-close']]: !isLeftMenuOpen })}>
                        <Menu
                            setIsLeftMenuOpen={newVal => this.setState({ isLeftMenuOpen: newVal })}
                            isLeftMenuOpen={isLeftMenuOpen}
                            menu={filteredMenu}
                            selectedKeyURL={selectedKeyURL}
                            me={me}
                        />
                    </div>
                    <div className={styles['main-layout']}>
                        <CommandBar
                            items={command}
                        />
                        <div className={styles['body-layout']}>
                            <div className={styles['body-content-layout']}>
                                <Breadcrumb
                                    items={breadcrumb}
                                    maxDisplayedItems={5}
                                    tooltipHostProps={{ content: undefined }}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderItem={(props, defaultRender) => (props.href
                                        ? (
                                            <Link to={props.href}>
                                                {defaultRender({ ...props, href: undefined })}
                                            </Link>
                                        )
                                        : defaultRender(props))}
                                />
                                {
                                    messageBar?.isDisplayed
                                    && (
                                        <>
                                            <MessageBar
                                                messageBarType={messageBar.type}
                                                isMultiline={false}
                                                truncated
                                                onDismiss={messageBar.isFixed ? undefined : () => setMessageBar({ isDisplayed: false })}
                                            >
                                                {(typeof messageBar.message === 'string'
                                                    ? this.tKey(/** @type {any} */(messageBar.message))
                                                    : this.tObj(/** @type {any} */(messageBar.message)))
                                                    || messageBar.message?.toString()}
                                            </MessageBar>
                                            <br />
                                        </>
                                    )
                                }
                                {children}
                                <br />

                            </div>
                            <footer>
                                <Link
                                    to="/legal-mentions"
                                >
                                    {this.tKey('trackingFooter', { name: me?.company?.name ?? 'Mousset' })}
                                </Link>
                            </footer>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

Layout.prototype.tKey = tKey
Layout.prototype.tObj = tObj
