import ApiHandler from 'requests/apiHandler'
import { ErrorBase } from 'requests/objects/_base'
import Transport from 'requests/objects/transport'

/**
 * TransportsHandler
 * @augments {ApiHandler<Transport, ErrorBase>}
 */
export default class TransportsHandler extends ApiHandler {
    constructor() {
        super({ type: Transport, errorType: ErrorBase, key: 'transports' })
    }

    /**
     * getWaybills
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {import('requests/apiHandler').RequestApi<Transport[]>} Request
     */
    getWaybills(params = {}) {
        const request = this.initFetchRequest({
            url: ['waybills'],
            method: 'GET',
            params,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => res.data?.transports?.map(x => new Transport(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get tracking data
     * @param {number} trackingToken trackingToken
     * @returns {import('requests/apiHandler').RequestApi<Transport>} Request
     */
    getTrackingData(trackingToken) {
        const request = this.initFetchRequest({ url: ['tracking', trackingToken] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Transport(res.data.transports))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Print Etiquette
     * @param {number} transportId transportId param
     * @param {string[]} emails emails param
     * @returns {any} empty object
     */
    share(transportId, emails) {
        const request = this.initFetchRequest({
            url: [transportId, 'share'],
            method: 'POST',
            data: { emails: emails.map(email => ({ email })) },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => { })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get all
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {import('requests/apiHandler').RequestPageApi<Transport>} Request
     */
    getAllFromPage(params = {}) {
        const request = this.initFetchRequest({ params })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => res.data).then(({
                    transports, totalRecords, totalPages,
                }) => ({
                    items: transports?.map(x => new Transport(x)) ?? [],
                    totalRecords,
                    totalPages,
                }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Export all
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {import('requests/apiHandler').RequestApi<{blob: Blob, fileName: string}>} Request
     */
    exportAll(params = {}) {
        const request = this.initFetchRequest({
            url: ['export'],
            method: 'GET',
            responseType: 'arraybuffer',
            params,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => ({
                    fileName: decodeURI(res.headers['content-disposition'])?.split('attachment;filename=')?.[1] ?? 'EXPORT-Transports-RAUD.xlsx',
                    blob: new Blob([res.data]),
                }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
