import File, { ErrorFile } from 'requests/objects/file'
// eslint-disable-next-line import/named
import ApiHandler from 'requests/apiHandler'
import { TransportFile } from 'requests/objects/transport'

/**
 * FilesHandler
 * @augments {ApiHandler<File, ErrorFile>}
 */
export default class FilesHandler extends ApiHandler {
    constructor() {
        super({ type: File, errorType: ErrorFile, key: 'files' })
    }

    /**
     * Get file from entity, require the folder name assiociated
     * @param {number} fileFolderId fileFolderId
     * @param {number} fileId fileId
     * @param {boolean} handlerError handlerError
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    getFile(fileFolderId, fileId, handlerError = true) {
        const request = this.initFetchRequest({
            method: 'GET',
            responseType: 'arraybuffer',
            params: { fileFolderId, fileId },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    if (handlerError)
                        throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * @param {number} transportFileId transportFileId
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    getTransportCMRFile(transportFileId) {
        const request = this.initFetchRequest({ url: ['cmr', transportFileId], responseType: 'arraybuffer' })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * @param {number[]} transportFileIds transportFileIds
     * @returns {import('requests/apiHandler').RequestApi<{blob: Blob, fileName: string, errors: TransportFile[]}>} Request
     */
    downloadWayBills(transportFileIds) {
        const request = this.initFetchRequest({
            url: ['waybills'],
            params: { transportFileIds },
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(async res => {
                    const contentType = res.headers['content-type']
                    const boundary = contentType.split('boundary=')[1]

                    // Convertir ArrayBuffer en string
                    const decoder = new TextDecoder('utf-8')
                    const content = decoder.decode(res.data)
                    const parts = content.split(`--${boundary}`)

                    const result = {
                        errors: [],
                        blob: null,
                        fileName: 'LOADING_SHEET-Transports-RAUD.xlsx',
                    }

                    return parts.reduce((acc, part) => {
                        if (part.includes('application/json')) {
                            const jsonContent = part.split('\r\n\r\n')[1]?.trim()
                            if (jsonContent && jsonContent !== '')
                                try {
                                    const jsonData = JSON.parse(jsonContent)
                                    acc.errors = jsonData.errors.map(x => new TransportFile(x)) || []
                                } catch (e) {
                                    // eslint-disable-next-line no-console
                                    console.error('Error parsing JSON part:', e)
                                }
                        } else if (part.includes('application/octet-stream')) {
                            const contentDisposition = part.match(/filename=(.*?)\r\n/)?.[1]
                            if (contentDisposition)
                                acc.fileName = decodeURI(contentDisposition)

                            // Créer le blob à partir des données binaires
                            const binaryContent = part.split('\r\n\r\n')[1]
                            if (binaryContent)
                                acc.blob = new Blob([binaryContent], {
                                    type: 'application/octet-stream',
                                })
                        }
                        return acc
                    }, result)
                })
                // .then(res => ({
                //     fileName: decodeURI(res.headers['content-disposition'])?.split('attachment;filename=')?.[1] ?? 'LOADING_SHEET-Transports-RAUD.xlsx',
                //     blob: new Blob([res.data]),
                // }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * @param {string} url url
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    getPdfFileFromUrl(url) {
        const request = this.initFetchRequest({
            url: ['pdf-file-url'],
            params: { url: encodeURI(url) },
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get user guide file
     * @param {string} lang lang
     * @returns {import('requests/apiHandler').RequestApi<{blob: Blob, fileName: string}>} Request
     */
    getUserGuide(lang) {
        const request = this.initFetchRequest({
            method: 'GET',
            responseType: 'arraybuffer',
            url: ['user-guide'],
            params: {
                lang,
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => ({
                    blob: new Blob([res.data], { type: 'application/pdf' }),
                    fileName: res.headers['content-disposition'].match(/filename=(.*\.pdf)/)[1],
                }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get file from id. This route handle retrieving file without being logged in.
     * @param {number} fileId fileId
     * @param {boolean} handlerError handlerError
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    getPublicFileById(fileId, handlerError = true) {
        const request = this.initFetchRequest({
            method: 'GET',
            responseType: 'arraybuffer',
            url: ['public', fileId],
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    if (handlerError)
                        throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} entityId entityId of linked entity that is connected to the file (client, company, home article)
     * @param {number} fileFolderId fileFolderId
     * @param {globalThis.File} file file
     * @param {number=} fileCategoryId fileCategoryId
     * @param {string=} fileUrl fileUrl
     * @returns {import('requests/apiHandler').RequestApi<File>} Returns
     */
    uploadFile(entityId, fileFolderId, file = undefined, fileCategoryId = undefined, fileUrl = undefined) {
        let request = null

        if (fileUrl) {
            request = this.initFetchRequest({
                method: 'POST',
                data: { url: fileUrl },
                params: {
                    entityId, fileFolderId, fileCategoryId,
                },
            })
        } else {
            const formData = new FormData()
            formData.append('file', file)

            request = this.initFetchRequest({
                method: 'POST',
                data: formData,
                params: {
                    entityId, fileFolderId, fileCategoryId,
                },
                headers: {
                    'Content-Type': 'multipart/form-data;',
                },
            })
        }

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new File(/** @type {any} */(res.data)?.files))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} entityId entityId
     * @param {number} fileFolderId fileFolderId
     * @param {number} fileId fileId
     * @returns {import('requests/apiHandler').RequestApi<File>} Request
     */
    removeFile(entityId, fileFolderId, fileId) {
        const request = this.initFetchRequest({
            method: 'DELETE',
            params: { entityId, fileFolderId, fileId },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(() => { })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
