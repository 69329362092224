/**
 * Check version and reload page if different version are inside
 */
export default async function checkVersion() {
    if (!['production', 'staging'].includes(process.env.NODE_ENV))
        return

    const currentVersion = process.env.REACT_APP_BUILD_TIMESTAMP ?? 'dev'

    try {
        const response = await fetch('/version.json')
        const serverVersion = await response.json()

        if (currentVersion !== serverVersion.version)
            window.location.reload()
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Erreur de vérification', error)
    }
}
