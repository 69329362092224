import React, { PureComponent } from 'react'
import {
    ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, buildColumns, Text,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
import User from 'requests/objects/user'
import { Link } from 'react-router-dom'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import userColumns from 'types/users/columns'
import Card from 'components/containers/card'
import styles from 'styles/pages/users/index.module.scss'
// import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import EProfile from 'types/users/enums/profile'
import { userProfiles, userProfilesWithSuperAdmin } from 'types/users/profiles'
import ECompany from 'types/companies/enums/company'
/** @typedef {import("app").AppProps} AppProps  */

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexUsers extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            columns: [],
            /** @type {User[]} Items found in API */
            items: [],
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { lang } = this.props

        if (lang !== prevProps.lang)
            this.init()

        // this.init()

        // this.setupBreadcrumb()

        // this.setupCommandBar()

        // this.setState({
        //     columns: this.buildColumns(),
        // })
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.usersHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('users'), key: 'user', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand(
            [
                {
                    key: 'new',
                    text: this.tKey('new'),
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/users/${NEW_PATH}`),
                    disabled: false,
                },
            ],
        )
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        this.setState({ columns: this.buildColumns() }, () => this.search())
    }

    /**
     * Init <DetailList>
     * @returns {import('@fluentui/react').IColumn[]} Columns
     */
    buildColumns() {
        const cols = userColumns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this.onColumnClick.bind(this, {
                colName: 'columns', dataName: ['items'], source: 'state', isFlatten: true,
            }),
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
            const name = cols[column.key]
            column.name = this.tKey(name) || name
            column.maxWidth = (() => {
                switch (name) {
                    case 'registrationNumber':
                    case 'lastname':
                    case 'firstname':
                    case 'company':
                    case 'profile':
                        return 130
                    case 'email':
                    case 'phoneNumber':
                        return 200
                    default:
                        return (column.name?.length ?? 1) * 12
                }
            })()
            column.minWidth = (column.name?.length ?? 1) * 5
            column.onRender = userColumns.find(x => x.name === name)?.onRender?.bind(this) ?? this.handleRenderColumn(userColumns, name, column)
        }

        return columns
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { usersHandler, me } = this.props

            try {
                const profileIds = me.profileId === EProfile.SuperAdmin
                    ? userProfilesWithSuperAdmin
                    : userProfiles

                if (me.company.companyId === ECompany.JetFreeze)
                    profileIds.push(EProfile.AdValorem)

                this.usersHandlerGetAll = usersHandler.getAll({ profileIds })
                const users = await this.usersHandlerGetAll.fetch()
                this.setState({
                    items: users.map(x => this.flattenObj(x)),
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            columns, status, items,
        } = this.state

        return (
            <main className={styles.index}>
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (
                            <Link
                                to={{
                                    pathname: `/users/${props.item?.userId}`,
                                }}
                            >
                                {defaultRender(props)}
                            </Link>
                        )}

                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                </Card>
            </main>
        )
    }
}

IndexUsers.prototype.onColumnClick = onColumnClick
IndexUsers.prototype.flattenObj = flattenObj
IndexUsers.prototype.handleRenderColumn = handleRenderColumn
IndexUsers.prototype.tKey = tKey
IndexUsers.prototype.tObj = tObj
